import { FC, useState } from "react";
import { CardProps } from "./types";
import {
  AddToCartWrapper,
  ApplicationsWrapper,
  CardLink,
  CardStyled,
  CommentTextWrapper,
  CounterWrapper,
  ImageWrapper,
  IncreaseDecreaseButton,
  ProductNameStyled,
  ProductValueStyled,
  QuantityValueStyled,
  TextWrapper,
} from "./styled";
import { MinusIcon } from "../../assets/minusIcon";
import { PlusIcon } from "../../assets/plusIcon";
import { SearchButtonStyled } from "../YearMakeModel/styled";
import { addToCartRequest } from "../../api/autoParts";

const Card: FC<CardProps> = ({ product, productCardConfig, addToCart }) => {
  const imageURL = product.imageUrl?.length ? product.imageUrl[0].src : "";
  const productUrl = `https://${product.productUrl}`;
  const fitmentLabel = document.getElementById("fitment-label-id")?.innerText;
  const cents = product.price.split(".")[1];
  const price = cents.length < 2 ? product.price + "0" : product.price;
  const [selectedQuantity, setSelectedQuantity] = useState<number>(1);

  const disableIncrement = product.quantity
    ? Number(product.quantity) <= selectedQuantity
    : true;
  const disableDecrement = selectedQuantity <= 1;

  const increaseQuantity = () => {
    if (!disableIncrement) setSelectedQuantity(selectedQuantity + 1);
  };

  const decreaseQuantity = () => {
    if (!disableDecrement) setSelectedQuantity(selectedQuantity - 1);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    if (Number(value) < 1) {
      setSelectedQuantity(1);
    } else if (product.quantity && Number(value) >= Number(product.quantity)) {
      setSelectedQuantity(Number(product.quantity));
    } else {
      setSelectedQuantity(Number(value));
    }
  };

  const addToCartClick = async () => {
    if (product.variandId && product.quantity) {
      const substring = product.variandId.split("/");
      const id = substring[substring.length - 1];
      let formData = {
        items: [
          {
            id: id,
            quantity: selectedQuantity,
            properties: {
              Fitment: fitmentLabel,
            },
          },
        ],
      };
      const resp = await addToCartRequest({ formData });
      console.log(resp);
    }
  };

  return (
    <CardStyled
      backgroundColor={productCardConfig?.card_background_color}
      hoverBackgroundColor={productCardConfig?.card_hover_background_color}
      borderWidth={productCardConfig?.card_border_width}
      borderColor={productCardConfig?.card_border_color}
      borderRadius={productCardConfig?.card_border_radius}
      hoverBorderColor={productCardConfig?.card_hover_border_color}
    >
      <div>
        <CardLink href={productUrl}>
          <ImageWrapper>
            <img src={imageURL} alt={product.name} />
          </ImageWrapper>
        </CardLink>

        <div>
          <ProductNameStyled
            color={productCardConfig?.card_product_name_font_color}
            fontSize={productCardConfig?.card_product_name_font_size}
            fontWeight={productCardConfig?.card_product_name_font_weight}
            height="60px"
          >
            {product.name}
          </ProductNameStyled>

          <ProductNameStyled
            color={productCardConfig?.card_product_price_font_color}
            fontSize={productCardConfig?.card_product_price_font_size}
            fontWeight={productCardConfig?.card_product_price_font_weight}
          >
            ${price}
          </ProductNameStyled>
          {product.applications && product.applications[0]?.supplier && (
            <TextWrapper>
              <ProductNameStyled
                color={productCardConfig?.card_product_price_font_color}
                fontSize={productCardConfig?.card_product_price_font_size}
                fontWeight={productCardConfig?.card_product_price_font_weight}
              >
                Supplier:
              </ProductNameStyled>
              <ProductValueStyled
                color={productCardConfig?.card_product_price_font_color}
                fontSize={productCardConfig?.card_product_price_font_size}
                fontWeight="500"
              >
                &nbsp;{product.applications[0].supplier}
              </ProductValueStyled>
            </TextWrapper>
          )}
          {product.applications && product.applications[0].brand ? (
            <TextWrapper>
              <ProductNameStyled
                color={productCardConfig?.card_product_price_font_color}
                fontSize={productCardConfig?.card_product_price_font_size}
                fontWeight={productCardConfig?.card_product_price_font_weight}
                height="60px"
              >
                Brand:
                <ProductValueStyled
                  color={productCardConfig?.card_product_price_font_color}
                  fontSize={productCardConfig?.card_product_price_font_size}
                  fontWeight="500"
                >
                  &nbsp;{product.applications[0].brand}
                </ProductValueStyled>
              </ProductNameStyled>
            </TextWrapper>
          ) : (
            <TextWrapper>
              <ProductNameStyled
                color={productCardConfig?.card_product_price_font_color}
                fontSize={productCardConfig?.card_product_price_font_size}
                fontWeight={productCardConfig?.card_product_price_font_weight}
                height="60px"
              >
                <ProductValueStyled
                  color={productCardConfig?.card_product_price_font_color}
                  fontSize={productCardConfig?.card_product_price_font_size}
                  fontWeight="500"
                />
              </ProductNameStyled>
            </TextWrapper>
          )}
          {product?.applications?.length ? (
            product.applications?.map((e, idx) => (
              <ApplicationsWrapper
                key={`${e.comment}+${idx}`}
                backgroundColor={
                  idx === 0
                    ? productCardConfig?.card_product_applications_background
                    : "#ffffff"
                }
                marginTop={idx === 1 ? 4 : 0}
                borderColor={
                  idx === 0
                    ? productCardConfig?.card_product_applications_background
                    : "#b4b4b4"
                }
                hidden={!e.location && !e.application && !e.comment}
              >
                {e?.location && (
                  <TextWrapper>
                    <ProductNameStyled
                      color={
                        productCardConfig?.card_product_applications_font_color
                      }
                      fontSize={productCardConfig?.card_product_price_font_size}
                      fontWeight={
                        productCardConfig?.card_product_price_font_weight
                      }
                    >
                      Location:
                    </ProductNameStyled>
                    <ProductNameStyled
                      color={
                        productCardConfig?.card_product_applications_font_color
                      }
                      fontSize={productCardConfig?.card_product_price_font_size}
                      fontWeight="500"
                    >
                      {e.location}
                    </ProductNameStyled>
                  </TextWrapper>
                )}
                {e.application && (
                  <TextWrapper>
                    <ProductNameStyled
                      color={
                        productCardConfig?.card_product_applications_font_color
                      }
                      fontSize={productCardConfig?.card_product_price_font_size}
                      fontWeight={
                        productCardConfig?.card_product_price_font_weight
                      }
                    >
                      Application:
                    </ProductNameStyled>
                    <ProductNameStyled
                      color={
                        productCardConfig?.card_product_applications_font_color
                      }
                      fontSize={productCardConfig?.card_product_price_font_size}
                      fontWeight="500"
                    >
                      &nbsp;{e.application}
                    </ProductNameStyled>
                  </TextWrapper>
                )}
                {e.comment && (
                  <CommentTextWrapper>
                    <ProductNameStyled
                      color={
                        productCardConfig?.card_product_applications_font_color
                      }
                      fontSize={productCardConfig?.card_product_price_font_size}
                      fontWeight={
                        productCardConfig?.card_product_price_font_weight
                      }
                    >
                      Comment:
                      <ProductValueStyled
                        color={
                          productCardConfig?.card_product_applications_font_color
                        }
                        fontSize={
                          productCardConfig?.card_product_price_font_size
                        }
                        fontWeight="500"
                      >
                        &nbsp;{e.comment}
                      </ProductValueStyled>
                    </ProductNameStyled>
                  </CommentTextWrapper>
                )}
              </ApplicationsWrapper>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
      {addToCart ? product.quantity ? (
        <AddToCartWrapper>
          <CounterWrapper>
            <IncreaseDecreaseButton
              onClick={decreaseQuantity}
              disabled={disableDecrement}
            >
              <MinusIcon color={disableDecrement ? "#b4b4b4" : "#0064FE"} />
            </IncreaseDecreaseButton>
            <QuantityValueStyled
              value={selectedQuantity}
              onChange={(e) => handleInputChange(e)}
            />
            <IncreaseDecreaseButton
              onClick={increaseQuantity}
              disabled={disableIncrement}
            >
              <PlusIcon color={disableIncrement ? "#b4b4b4" : "#0064FE"} />
            </IncreaseDecreaseButton>
          </CounterWrapper>

          <div>
            <SearchButtonStyled
              type="button"
              className="search-bar-search-button-class"
              onClick={addToCartClick}
              disabled={!selectedQuantity || !product.quantity}
            >
              Add to cart
            </SearchButtonStyled>
          </div>
        </AddToCartWrapper>
      ) : (
        <ProductNameStyled
          color={productCardConfig?.card_product_applications_font_color}
          fontSize={productCardConfig?.card_product_price_font_size}
          fontWeight={productCardConfig?.card_product_price_font_weight}
        >
          Sold Out
        </ProductNameStyled>
      ) : <></>}
    </CardStyled>
  );
};

export default Card;
