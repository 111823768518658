import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://ec7db428eaf25ed8f5e294565e68ffa6@o4503993529925632.ingest.us.sentry.io/4507297398587392",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.25, //  Capture 100% of the transactions
});

window.addEventListener('load', function () {
  let sptAttribute = null;
  let shopifyDomain = null;
  let reservedSptAttribute = null;

  const sptAttributeElement = document
    ?.evaluate(
      '//div[@sptselector]',
      document,
      null,
      XPathResult.ANY_TYPE,
      null
    )
    ?.iterateNext() as HTMLElement | null;
  if (sptAttributeElement) {
    sptAttribute = sptAttributeElement?.getAttribute('sptselector');
  }

  const reservedSptAttributeElement = document
    ?.evaluate(
      '//div[@reservedsptselector]',
      document,
      null,
      XPathResult.ANY_TYPE,
      null
    )
    ?.iterateNext() as HTMLElement | null;
  if (reservedSptAttributeElement) {
    reservedSptAttribute = reservedSptAttributeElement?.getAttribute('reservedsptselector');
  }

  const shopifyDomainElement = document
    ?.evaluate(
      '//div[@shopifydomain]',
      document,
      null,
      XPathResult.ANY_TYPE,
      null
    )
    ?.iterateNext() as HTMLElement | null;
  if (shopifyDomainElement) {
    shopifyDomain = shopifyDomainElement?.getAttribute('shopifydomain');
  }

  console.log(shopifyDomain, "domain");

  const sptElement = document?.querySelector(`.${sptAttribute}`);
  const reservedSptElement = document?.querySelector(`.${reservedSptAttribute}`);

  sptElement?.addEventListener('click', function () {
    let mainElement: HTMLElement | null =
      document.getElementById('spt-react-app');
    if (!!mainElement) {
      mainElement!.style.display = 'block';
    }
  });
  reservedSptElement?.addEventListener('click', function () {
    let mainElement: HTMLElement | null =
      document.getElementById('spt-react-app');
    if (!!mainElement) {
      mainElement!.style.display = 'block';
    }
  });

  let newDiv = document.createElement('div');
  newDiv.className = 'app';

  if (newDiv && sptAttributeElement) {
    document.body.insertAdjacentElement('beforeend', newDiv);
  }
  if (newDiv && reservedSptElement) {
    document.body.insertAdjacentElement('beforeend', newDiv);
  }

  ReactDOM.createRoot(newDiv).render(<App store={shopifyDomain} />);
});
