import { EmptyStateIcon } from "../../common/components";
import { IconWrapper, Wrapper } from "./styled";

const EmptyStateComponent = ({color}: {color: string}) => {
  return (
    <Wrapper>
      <IconWrapper>
        <EmptyStateIcon color={color} />
      </IconWrapper>
      <h1>No products found</h1>
    </Wrapper>
  );
};

export default EmptyStateComponent;
