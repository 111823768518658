import { styled } from "styled-components";
import { StyledProps } from "../../common/types";

export const HeaderStyled = styled.header<StyledProps>`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 40px;
  @media (max-width: 1025px) {
    padding: 20px 10px;
  }
  height: auto;
  min-height: 40px;
  max-height: 800px;
  @media (min-width: 750px) {
    height: auto;
  }
`;
