import { FC, memo, useEffect, useState } from "react";
import { DynamicSelectorProps } from "./types";
import {
  Wrapper,
  TabsStyled,
  TabButton,
  TabsButtonSections,
  TabsWrapper,
  CloseButton,
  ErrorWrapperStyled,
  RegularText,
  MobileText,
  MobileSearchBar,
  CollapsedSearchBar,
  MobileWrapper,
  DesktopWrapper,
  ButtonWrapper,
  TextWrapper,
  CollapsedWrapper,
  ButtonCollapsed,
  TextHeading,
  SearchBarMobile,
  MobileCloseIcon,
  OpenedTransitionWrapper,
  CollapsedTransitionWrapper,
} from "./styled";

import { DynamicSelectorTabs } from "../../common/constants/dynamicSelector";
import YearMakeModel from "../YearMakeModel";
import { CloseIcon } from "../../common/components";
import {
  SelectorsLocalStorage,
  SptLocalStorageVariables,
} from "../../common/constants/localStorage";
import VIN from "../VIN";
import LicensePlate from "../LicensePlate";
import { getLocalStorageValue } from "../../common/helpers";

const DynamicSelector: FC<DynamicSelectorProps> = ({
  selectorsConfig,
  setSearchTerm,
  licenseKey,
  databaseUrl,
  setData,
  vinLookupEnabled,
  licensePlateLookupEnabled,
  setTab,
  searchError,
  partTypeAllOption,
  store,
  collapsed,
  setCollapsed,
  handleShowFilters,
  partTypeEnabled,
  engineEbabled,
}) => {
  const [currentTab, setCurrentTab] = useState<DynamicSelectorTabs>(
    DynamicSelectorTabs.YearMakeModal
  );
  const [apiError, setApiError] = useState<boolean>(false);
  const isYearTab = currentTab === DynamicSelectorTabs.YearMakeModal;
  const isVinTab = currentTab === DynamicSelectorTabs.VIN;
  const isLicensePlateTab = currentTab === DynamicSelectorTabs.LicensePlateTab;

  const handleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleClose = () => {
    let elem = document.getElementById("spt-react-app");
    if (elem) {
      elem.style.display = "none";
    }

    const keysToRemove = [
      SptLocalStorageVariables.SPT_FILTERS,
      SptLocalStorageVariables.SPT_PRODUCTS,
      SptLocalStorageVariables.SPT_SELECTED_FILTERS,
      SptLocalStorageVariables.SPT_SELECTORS,
    ];

    keysToRemove.forEach((k) => localStorage.removeItem(k));
  };

  const year = getLocalStorageValue(
    SelectorsLocalStorage.SELECTED_YEAR_VALUE_NAME
  );
  const make = getLocalStorageValue(
    SelectorsLocalStorage.SELECTED_MAKE_VALUE_NAME
  );
  const model = getLocalStorageValue(
    SelectorsLocalStorage.SELECTED_MODEL_VALUE_NAME
  );

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
  const handleCollapsedAnimation: any = async () => {
    const el = document.getElementById("mobile-searchbar-id");
    if (el) {
      if (collapsed) {
        el.style.overflow = "hidden";
      } else {
        await delay(500);
        el.style.overflow = "visible";
      }
    }
  };
  useEffect(() => {
    handleCollapsedAnimation()
  }, [collapsed])

  return (
    <Wrapper>
      {/* mobile */}
      <MobileSearchBar>
        <MobileWrapper>
          <CollapsedTransitionWrapper collapsed={collapsed}>
            <CollapsedSearchBar>
              <CollapsedWrapper>
                <TextWrapper className="search-bar-active-tab-text" fontSize={selectorsConfig?.search_button_font_size}>
                  Your Vehicle: &nbsp;<div className="search-bar-active-tab-text">{`${year} ${make} ${model}`}</div>
                </TextWrapper>
                <ButtonWrapper>
                  <ButtonCollapsed
                    type="button"
                    className="search-bar-search-button-class"
                    onClick={handleCollapsed}
                    backgroundColor={
                      selectorsConfig?.search_button_background_color
                    }
                    hoverBackgroundColor={
                      selectorsConfig?.search_button_hover_background_color
                    }
                    color={selectorsConfig?.search_button_font_color}
                    fontSize={selectorsConfig?.search_button_font_size}
                    fontWeight={selectorsConfig?.search_button_font_weight}
                  >
                    Edit Vehicle
                  </ButtonCollapsed>
                  <ButtonCollapsed
                    type="button"
                    className="search-bar-search-button-class"
                    onClick={handleShowFilters}
                    backgroundColor={
                      selectorsConfig?.search_button_background_color
                    }
                    hoverBackgroundColor={
                      selectorsConfig?.search_button_hover_background_color
                    }
                    color={selectorsConfig?.search_button_font_color}
                    fontSize={selectorsConfig?.search_button_font_size}
                    fontWeight={selectorsConfig?.search_button_font_weight}
                  >
                    Select Filters
                  </ButtonCollapsed>
                </ButtonWrapper>
              </CollapsedWrapper>
            </CollapsedSearchBar>
          </CollapsedTransitionWrapper>
          <OpenedTransitionWrapper collapsed={collapsed}>
            <SearchBarMobile
              id="mobile-searchbar-id"
            >
              <TextHeading className="search-bar-active-tab-text">
                Select Your Vehicle
              </TextHeading>
              {!apiError && !searchError && (
                <TabsButtonSections>
                  <TabButton
                    className={
                      currentTab === DynamicSelectorTabs.YearMakeModal
                        ? "search-bar-active-tab-class"
                        : "search-bar-default-tab-class"
                    }
                    isActive={currentTab === DynamicSelectorTabs.YearMakeModal}
                    type="button"
                    onClick={() => {
                      setCurrentTab(DynamicSelectorTabs.YearMakeModal);
                      setTab(DynamicSelectorTabs.YearMakeModal);
                    }}
                    color={selectorsConfig?.tab_font_color}
                    fontSize={selectorsConfig?.tab_font_size}
                    fontWeight={selectorsConfig?.tab_font_weight}
                    backgroundColor={selectorsConfig?.background_color}
                    notActiveTabBackgroundColor={
                      selectorsConfig?.active_background_color
                    }
                  >
                    <RegularText
                      className={
                        currentTab === DynamicSelectorTabs.VIN
                          ? "search-bar-active-tab-text"
                          : "search-bar-default-tab-text"
                      }
                    >
                      Year-Make-Model
                    </RegularText>
                    <MobileText
                      className={
                        currentTab === DynamicSelectorTabs.VIN
                          ? "search-bar-active-tab-text"
                          : "search-bar-default-tab-text"
                      }
                    >
                      Year
                    </MobileText>
                  </TabButton>
                  {vinLookupEnabled && (
                    <TabButton
                      className={
                        currentTab === DynamicSelectorTabs.VIN
                          ? "search-bar-active-tab-class"
                          : "search-bar-default-tab-class"
                      }
                      isActive={currentTab === DynamicSelectorTabs.VIN}
                      type="button"
                      onClick={() => {
                        setCurrentTab(DynamicSelectorTabs.VIN);
                        setTab(DynamicSelectorTabs.VIN);
                      }}
                    >
                      VIN
                    </TabButton>
                  )}
                  {licensePlateLookupEnabled && (
                    <TabButton
                      className={
                        currentTab === DynamicSelectorTabs.LicensePlateTab
                          ? "search-bar-active-tab-class"
                          : "search-bar-default-tab-class"
                      }
                      isActive={
                        currentTab === DynamicSelectorTabs.LicensePlateTab
                      }
                      type="button"
                      onClick={() => {
                        setCurrentTab(DynamicSelectorTabs.LicensePlateTab);
                        setTab(DynamicSelectorTabs.LicensePlateTab);
                      }}
                    >
                      License Plate
                    </TabButton>
                  )}
                </TabsButtonSections>
              )}
              <TabsWrapper>
                {apiError || searchError ? (
                  <ErrorWrapperStyled
                    backgroundColor={selectorsConfig?.background_color}
                    className="search-bar-tab-class"
                  >
                    <div className="search-bar-active-tab-class">
                      Currently unable to process part lookups. Please try again
                      later.
                    </div>
                  </ErrorWrapperStyled>
                ) : (
                  <TabsStyled
                    backgroundColor={selectorsConfig?.background_color}
                    className="search-bar-tab-class"
                  >
                    {isYearTab && (
                      <YearMakeModel
                        selectorsConfig={selectorsConfig}
                        setSearchTerm={setSearchTerm}
                        licenseKey={licenseKey}
                        databaseUrl={databaseUrl}
                        setError={setApiError}
                        partTypeAllOption={partTypeAllOption}
                        setCollapsed={setCollapsed}
                        partTypeEnabled={partTypeEnabled}
                        engineEbabled={engineEbabled}
                      />
                    )}
                    {isVinTab && (
                      <VIN
                        selectorsConfig={selectorsConfig}
                        setSearchTerm={setSearchTerm}
                        licenseKey={licenseKey}
                        databaseUrl={databaseUrl}
                        setCollapsed={setCollapsed}
                      />
                    )}
                    {isLicensePlateTab && (
                      <LicensePlate
                        selectorsConfig={selectorsConfig}
                        setSearchTerm={setSearchTerm}
                        licenseKey={licenseKey}
                        databaseUrl={databaseUrl}
                        setData={setData}
                        store={store}
                        setCollapsed={setCollapsed}
                      />
                    )}
                  </TabsStyled>
                )}
              </TabsWrapper>
            </SearchBarMobile>
          </OpenedTransitionWrapper>
        </MobileWrapper>
        <CloseButton
          className="search-bar-close-button"
          backgroundColor={selectorsConfig?.close_button_background_color}
          hoverBackgroundColor={
            selectorsConfig?.close_button_hover_background_color
          }
          onClick={handleClose}
        >
          <MobileCloseIcon>
            <CloseIcon
              color={selectorsConfig?.close_button_icon_color || "#ffffff"}
            />
          </MobileCloseIcon>
        </CloseButton>
      </MobileSearchBar>

      {/* desktop */}
      <DesktopWrapper>
        {!apiError && !searchError && (
          <TabsButtonSections>
            <TabButton
              className={
                currentTab === DynamicSelectorTabs.YearMakeModal
                  ? "search-bar-active-tab-class"
                  : "search-bar-default-tab-class"
              }
              isActive={currentTab === DynamicSelectorTabs.YearMakeModal}
              type="button"
              onClick={() => {
                setCurrentTab(DynamicSelectorTabs.YearMakeModal);
                setTab(DynamicSelectorTabs.YearMakeModal);
              }}
              color={selectorsConfig?.tab_font_color}
              fontSize={selectorsConfig?.tab_font_size}
              fontWeight={selectorsConfig?.tab_font_weight}
              backgroundColor={selectorsConfig?.background_color}
              notActiveTabBackgroundColor={
                selectorsConfig?.active_background_color
              }
            >
              <RegularText
                className={
                  currentTab === DynamicSelectorTabs.VIN
                    ? "search-bar-active-tab-text"
                    : "search-bar-default-tab-text"
                }
              >
                Year-Make-Model
              </RegularText>
              <MobileText
                className={
                  currentTab === DynamicSelectorTabs.VIN
                    ? "search-bar-active-tab-text"
                    : "search-bar-default-tab-text"
                }
              >
                Year
              </MobileText>
            </TabButton>
            {vinLookupEnabled && (
              <TabButton
                className={
                  currentTab === DynamicSelectorTabs.VIN
                    ? "search-bar-active-tab-class"
                    : "search-bar-default-tab-class"
                }
                isActive={currentTab === DynamicSelectorTabs.VIN}
                type="button"
                onClick={() => {
                  setCurrentTab(DynamicSelectorTabs.VIN);
                  setTab(DynamicSelectorTabs.VIN);
                }}
              >
                VIN
              </TabButton>
            )}
            {licensePlateLookupEnabled && (
              <TabButton
                className={
                  currentTab === DynamicSelectorTabs.LicensePlateTab
                    ? "search-bar-active-tab-class"
                    : "search-bar-default-tab-class"
                }
                isActive={currentTab === DynamicSelectorTabs.LicensePlateTab}
                type="button"
                onClick={() => {
                  setCurrentTab(DynamicSelectorTabs.LicensePlateTab);
                  setTab(DynamicSelectorTabs.LicensePlateTab);
                }}
              >
                License Plate
              </TabButton>
            )}
          </TabsButtonSections>
        )}
        <TabsWrapper>
          {apiError || searchError ? (
            <ErrorWrapperStyled
              backgroundColor={selectorsConfig?.background_color}
              className="search-bar-tab-class"
            >
              <div className="search-bar-active-tab-class">
                Currently unable to process part lookups. Please try again
                later.
              </div>
            </ErrorWrapperStyled>
          ) : (
            <TabsStyled
              backgroundColor={selectorsConfig?.background_color}
              className="search-bar-tab-class"
            >
              {isYearTab && (
                <YearMakeModel
                  selectorsConfig={selectorsConfig}
                  setSearchTerm={setSearchTerm}
                  licenseKey={licenseKey}
                  databaseUrl={databaseUrl}
                  setError={setApiError}
                  partTypeAllOption={partTypeAllOption}
                  setCollapsed={setCollapsed}
                  partTypeEnabled={partTypeEnabled}
                  engineEbabled={engineEbabled}
                />
              )}
              {isVinTab && (
                <VIN
                  selectorsConfig={selectorsConfig}
                  setSearchTerm={setSearchTerm}
                  licenseKey={licenseKey}
                  databaseUrl={databaseUrl}
                  setCollapsed={setCollapsed}
                />
              )}
              {isLicensePlateTab && (
                <LicensePlate
                  selectorsConfig={selectorsConfig}
                  setSearchTerm={setSearchTerm}
                  licenseKey={licenseKey}
                  databaseUrl={databaseUrl}
                  setData={setData}
                  store={store}
                  setCollapsed={setCollapsed}
                />
              )}
            </TabsStyled>
          )}

          <CloseButton
            className="search-bar-close-button"
            backgroundColor={selectorsConfig?.close_button_background_color}
            hoverBackgroundColor={
              selectorsConfig?.close_button_hover_background_color
            }
            onClick={handleClose}
          >
            <CloseIcon
              color={selectorsConfig?.close_button_icon_color || "#ffffff"}
            />
          </CloseButton>
        </TabsWrapper>
      </DesktopWrapper>
    </Wrapper>
  );
};

export default memo(DynamicSelector);
