import { styled } from 'styled-components';
import { InputStyledProps } from './types';

export const InputStyled = styled.div<InputStyledProps>`
  display: flex;
  flex-direction: column;
  position: relative !important;
  width: 100% !important;

  & > label {
    margin-bottom: 5px;
    font-size: 14px !important;
    color: #ffffff !important;
    font-weight: 600 !important;
  }
  & > input {
    height: 44px !important;
    min-width: 200px !important;
    width: 100% !important;
    background-color: #ffffff !important;
    border: 1px solid;
    box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
    border-radius: 8px;
    border-color: transparent !important;
    outline: none !important;
    padding: 0 12px;
    color: ${({ color }) => color || '#000000'} !important;
    font-size: ${({ fontSize }) => fontSize || '14px'} !important;
    font-weight: ${({ fontWeight }) => fontWeight || 500} !important;
  }
  & > span {
    position: absolute !important;
    bottom: 14px;
    right: 12px;
    font-size: 14px !important;
    color: ${({ spanError }) => (spanError ? '#b91c1c' : '#000000')}!important;
    font-weight: 500 !important;
    line-height: normal !important;
  }
`;
