import { styled } from "styled-components";
import { StyledProps } from "../../common/types";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr max-content;
  gap: 12px;
  width: 100%;

  @media (max-width: 980px) {
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr 1fr 1fr;
    > div:first-of-type {
      > input {
        text-align: center;
      }
    }
  }
`;

export const SearchButtonStyled = styled.button<StyledProps>`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 8px 16px !important;
  margin-left: 12px !important;
  border-radius: 8px !important;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "#1d4ed8"} !important;
  color: ${({ color }) => color || "#ffffff"} !important;
  font-size: ${({ fontSize }) => fontSize || "14px"} !important;
  font-weight: ${({ fontWeight }) => fontWeight || 600} !important;
  width: 100px !important;
  min-width: fit-content !important;
  height: 44px !important;
  cursor: pointer !important;

  &:hover {
    box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.1);
  }

  &:disabled {
    background-color: #cdcdcd !important;
    cursor: not-allowed !important;
    box-shadow: rgba(16, 24, 40, 0.12) 1px 1px 2px;
    &:hover {
      box-shadow: none;
    }
  }

  @media (max-width: 980px) {
    width: 100% !important;
    margin-left: 0px !important;
    justify-content: center !important;
  }
`;
