import { styled } from "styled-components";
import { MobileStyledProps, StyledProps } from "../../common/types";
import { TabsButtonsProps } from "./types";

export const Wrapper = styled.div`
  width: 100%;
  display: block;
  @media (min-width: 750px) {
    display: flex;
    justify-content: center;
  }
`;

export const CollapsedWrapper = styled.div`
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
  margin-top: 8px;
`;

export const ButtonCollapsed = styled.button<StyledProps>`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 4px 16px !important;
  border-radius: 8px !important;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "#1d4ed8"} !important;
  color: ${({ color }) => color || "#ffffff"} !important;
  font-size: "14px" !important;
  font-weight: ${({ fontWeight }) => fontWeight || 600} !important;
  width: 100px !important;
  height: 36px !important;
  cursor: pointer !important;

  &:hover {
    box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.1);
  }

  &:disabled {
    background-color: #7f7f7f !important;
  }

  @media (max-width: 980px) {
    margin-top: 5px !important;
    margin-left: 0 !important;
    width: 100% !important;
    justify-content: center !important;
  }
`;

export const MobileSearchBar = styled.div`
  @media (min-width: 750px) {
    display: none;
  }
`;

export const TextWrapper = styled.div<StyledProps>`
  margin-right: 12px;
  width: 160px;
  font-weight: 700 !important;
  display: flex;
  width: 100%;
  align-items: center;
  > div {
    font-weight: 400 important;
    margin: 0px !important;
    padding: 0px !important;
  }
`;

export const TextHeading = styled.div<MobileStyledProps>`
  margin-right: 12px;
  margin-bottom: 8px;
  width: 160px;
  font-weight: 500 !important;
  display: flex;
  width: 100%;
  align-items: center;
`;

export const MobileWrapper = styled.section<MobileStyledProps>`
  display: flex;
  justify-content: center;
  flex-direction: ${({ collapsedSearchBar }) =>
    collapsedSearchBar ? "row" : "column"};
  border-radius: 8px;
  width: 100%;
  max-width: 1240px;
  height: auto;
`;

export const SearchBarMobile = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
`;

export const CollapsedTransitionWrapper = styled.div<StyledProps>`
  display: grid;
  grid-template-rows: ${({ collapsed }) => (collapsed ? "1fr" : "0fr")};
  transition: grid-template-rows 0.5s ease-out;
`;

export const OpenedTransitionWrapper = styled.div<StyledProps>`
  display: grid;
  grid-template-rows: ${({ collapsed }) => (collapsed ? "0fr" : "1fr")};
  transition: grid-template-rows 0.5s ease-out;
`;

export const CollapsedSearchBar = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

export const DesktopWrapper = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  max-width: 1240px;

  @media (max-width: 980px) {
    margin-top: 30px;
  }
  @media (max-width: 750px) {
    display: none;
  }
`;

export const ClearButtonStyled = styled.button`
  position: absolute;
  right: 10px;
  z-index: 10;
  height: 20px;
  width: 20px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    height: fit-content !important;
    cursor: pointer !important;
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const TabsWrapper = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const TabsStyled = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  border-top-left-radius: 0px;
  width: 100%;
  padding: 12px 20px;
  max-width: 1240px;

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

export const ErrorWrapperStyled = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 8px;
  border-top-left-radius: 8px !important;
  width: 100%;
  padding: 12px 20px;
  max-width: 1240px;

  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

export const TabsButtonSections = styled.div`
  button {
    margin-right: 3px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  button:last-of-type {
    margin-right: 0px;
  }
`;
export const TabButton = styled.button<TabsButtonsProps>`
  cursor: pointer;
  padding: 8px 20px;
  padding-top: ${({ isActive }) => (isActive ? "12px" : "10px")};
  border-radius: 8px;
`;

export const MobileText = styled.p`
  @media (max-width: 750px) {
    display: inline;
  }
  display: none;
`;

export const RegularText = styled.p`
  @media (max-width: 750px) {
    display: none;
  }
  display: inline;
`;

export const CloseButton = styled.div<StyledProps>`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 20px !important;
  min-height: 20px !important;
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;

  background-color: ${({ backgroundColor }) =>
    backgroundColor || "#b91c1c"} !important;
  border-radius: 50% !important;
  margin-left: 20px !important;
  color: white !important;
  cursor: pointer !important;

  &:hover {
    box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.1);
  }
  @media (max-width: 980px) {
    position: absolute !important;
    top: 20px !important;
    right: 10px !important;
  }
  @media (min-width: 750px) {
    min-width: 40px !important;
    width: 40px !important;
    height: 40px !important;
  }
`;

export const MobileCloseIcon = styled.div`
  > svg {
    width: 10px;
    height: 10px;
  }
`;
