import { styled } from 'styled-components';
import { StyledProps } from '../../types';

export const InputSearchStyled = styled.header<StyledProps>`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 8px;
  width: 100%;
  padding: 0px 10px;
  background-color: ${(props) => props.backgroundColor};
  > div:first-of-type {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

export const IconStyled = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  cursor: default;
  padding-inline-start: 12px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const InputStyled = styled.input<StyledProps>`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  padding-inline-start: 40px;
  border: none;
  border-radius: 8px;
  background: transparent;
  outline: none;
  color: ${(props) => props.color};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
`;

export const ClearButtonStyled = styled.button`
  position: absolute;
  right: 10px;
  z-index: 10;
  height: 20px;
  width: 20px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    height: fit-content;
    cursor: pointer;
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const SearchButtonStyled = styled.button<StyledProps>`
  display: flex !important;
  align-items: center !important;
  padding: 8px 16px !important;
  margin-left: 12px !important;
  border-radius: 8px !important;
  background-color: ${(props) => props.backgroundColor} !important;
  color: ${(props) => props.color || '#ffffff'} !important;
  font-size: ${(props) => props.fontSize} !important;
  font-weight: ${(props) => props.fontWeight} !important;

  &:hover {
    box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.1);
  }
`;

export const AutocompleteList = styled.div`
  position: absolute !important;
  background-color: rgb(249, 250, 251);
  color: #000000;
  width: 100%;
  height: inherit;
  max-height: 700px;
  border-radius: 8px;
  top: calc(100% + 5px);
  left: 0;
  z-index: 1000;
  overflow-y: auto;
  display: grid;
  grid-auto-rows: max-content;
  gap: 8px;
  padding: 15px 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  > div {
    border-radius: 8px;
    padding: 5px 10px;
    cursor: pointer;
    &:hover {
      background: #e8e8e8;
    }
  }

  -webkit-animation: fadeInFromNone 0.2s ease-in-out;
  -moz-animation: fadeInFromNone 0.2s ease-in-out;
  -o-animation: fadeInFromNone 0.2s ease-in-out;
  animation: fadeInFromNone 0.2s ease-in-out;

  @-webkit-keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  @-moz-keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  @-o-keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  @keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }
`;

export const SkeletonStyled = styled.div`
  &:hover {
    background: none !important;
  }
`;

export const SkeletonItem = styled.div`
  display: grid;
  height: 30px;
  align-items: center;
  gap: 20px;
  grid-template-columns: 30% 30%;

  h2 {
    background-color: #d7d7d7 !important;
    height: 10px;
    width: 100%;
  }
`;
